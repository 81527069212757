import {
  URL_ADMIN,
  URL_CHANGE_PASSWORD,
  URL_DASHBOARD,
  URL_DIRECTORY,
  URL_EMAIL_TEMPLATE,
  URL_FEED_FORUM,
  URL_FEED_FORUM_COMMENT,
  URL_FIRST_TIME_CHANGE_PASSWORD,
  URL_FORUM_REPORT,
  URL_GENERAL_REPORT,
  URL_HEALTH_ASSESSMENT,
  URL_HEALTH_ASSESSMENT_REPORT,
  URL_NOTIFICATION,
  URL_NOTIFICATION_TEMPLATE,
  URL_QUICK_GUIDE,
  URL_QUICK_GUIDE_LINK_REPORT,
  URL_QUICK_GUIDE_POST_REPORT,
  URL_SCREENING_REPORT,
  URL_SCREENING_TOOLS,
  URL_SCREENING_TOOLS_USER,
  URL_SEARCH_DIRECTORY_REPORT,
  URL_SEARCH_QUICK_GUIDE_REPORT,
  URL_STATIC_PAGE,
  URL_USERS,
} from "Helpers/Paths";
import React from "react";

const Dashboard = React.lazy(() =>
  import("Components/Pages/Dashboard/Dashboard")
);
const QuickGuide = React.lazy(() =>
  import("Components/Pages/QuickGuide/QuickGuide")
);
const StaticPage = React.lazy(() =>
  import("Components/Pages/StaticPage/StaticPage")
);
const Email = React.lazy(() => import("Components/Pages/Email/Email"));
const ChangePassword = React.lazy(() =>
  import("Components/Pages/ChangePassword/ChangePassword")
);
const ScreeningTools = React.lazy(() =>
  import("Components/Pages/ScreeningTools/ScreeningTools")
);
const ScreeningToolUser = React.lazy(() =>
  import("Components/Pages/ScreeningToolUser/ScreeningToolUser")
);
const Users = React.lazy(() => import("Components/Pages/Users/Users"));
const Directory = React.lazy(() =>
  import("Components/Pages/Directory/Directory")
);
const FeedForum = React.lazy(() =>
  import("Components/Pages/FeedForum/FeedForum")
);
const FeedForumComment = React.lazy(() =>
  import("Components/Pages/FeedForum/Comment/Comment")
);
const Admin = React.lazy(() => import("Components/Pages/Admin/Admin"));
const Notification = React.lazy(() =>
  import("Components/Pages/Notification/Notification")
);
const ForumMetrics = React.lazy(() =>
  import("Components/Pages/Reports/ForumMetrics/ForumMetrics")
);
const GeneralMetrics = React.lazy(() =>
  import("Components/Pages/Reports/GeneralMetrics/GeneralMetrics")
);
const ScreeningToolMetrics = React.lazy(() =>
  import("Components/Pages/Reports/ScreeningToolMetrics/ScreeningToolMetrics")
);
const QuickGuidePostMetrics = React.lazy(() =>
  import("Components/Pages/Reports/QuickGuideMetrics/PostReport")
);
const QuickGuideLinkMetrics = React.lazy(() =>
  import("Components/Pages/Reports/QuickGuideMetrics/LinkReport")
);
const HealthAssessmentMetrics = React.lazy(() =>
  import(
    "Components/Pages/Reports/HealthAssessmentMetrics/HealthAssessmentMetrics"
  )
);
const QuickGuideSearch = React.lazy(() =>
  import("Components/Pages/Reports/SearchMetrics/QuickGuideSearch")
);
const DirectorySearch = React.lazy(() =>
  import("Components/Pages/Reports/SearchMetrics/DirectorySearch")
);
const HealthAssessment = React.lazy(() =>
  import("Components/Pages/HealthAssessment/HealthAssessment")
);
const FirstTimeLogin = React.lazy(() =>
  import("Components/Pages/ChangePassword/FirstTimeLogin/FirstTimeLogin")
);
const NotificationTemplate = React.lazy(() =>
  import("Components/Pages/NotificationTemplate/NotificationTemplate")
);

const RoutesList = [
  {
    path: URL_DASHBOARD,
    exact: true,
    Component: <Dashboard />,
    hasChildren: false,
  },
  {
    path: URL_QUICK_GUIDE,
    exact: true,
    Component: <QuickGuide />,
    hasChildren: false,
  },
  {
    path: URL_STATIC_PAGE,
    exact: true,
    Component: <StaticPage />,
    hasChildren: false,
  },
  {
    path: URL_EMAIL_TEMPLATE,
    exact: true,
    Component: <Email />,
    hasChildren: false,
  },
  {
    path: URL_CHANGE_PASSWORD,
    exact: true,
    Component: <ChangePassword />,
    hasChildren: false,
  },
  {
    path: URL_SCREENING_TOOLS,
    exact: true,
    Component: <ScreeningTools />,
    hasChildren: false,
  },
  {
    path: URL_SCREENING_TOOLS_USER,
    exact: true,
    Component: <ScreeningToolUser />,
    hasChildren: false,
  },
  {
    path: URL_USERS,
    exact: true,
    Component: <Users />,
  },
  {
    path: URL_DIRECTORY,
    exact: true,
    Component: <Directory />,
    hasChildren: false,
  },
  {
    path: URL_FEED_FORUM,
    exact: true,
    Component: <FeedForum />,
    hasChildren: false,
  },
  {
    path: URL_FEED_FORUM_COMMENT,
    exact: true,
    Component: <FeedForumComment />,
    hasChildren: false,
  },
  {
    path: URL_ADMIN,
    exact: true,
    Component: <Admin />,
    hasChildren: false,
  },
  {
    path: URL_NOTIFICATION,
    exact: true,
    Component: <Notification />,
    hasChildren: false,
  },
  {
    path: URL_FORUM_REPORT,
    exact: true,
    Component: <ForumMetrics />,
    hasChildren: false,
  },
  {
    path: URL_GENERAL_REPORT,
    exact: true,
    Component: <GeneralMetrics />,
    hasChildren: false,
  },
  {
    path: URL_SCREENING_REPORT,
    exact: true,
    Component: <ScreeningToolMetrics />,
    hasChildren: false,
  },
  {
    path: URL_QUICK_GUIDE_POST_REPORT,
    exact: true,
    Component: <QuickGuidePostMetrics />,
    hasChildren: false,
  },
  {
    path: URL_QUICK_GUIDE_LINK_REPORT,
    exact: true,
    Component: <QuickGuideLinkMetrics />,
    hasChildren: false,
  },
  {
    path: URL_HEALTH_ASSESSMENT_REPORT,
    exact: true,
    Component: <HealthAssessmentMetrics />,
    hasChildren: false,
  },
  {
    path: URL_SEARCH_QUICK_GUIDE_REPORT,
    exact: true,
    Component: <QuickGuideSearch />,
    hasChildren: false,
  },
  {
    path: URL_SEARCH_DIRECTORY_REPORT,
    exact: true,
    Component: <DirectorySearch />,
    hasChildren: false,
  },
  {
    path: URL_HEALTH_ASSESSMENT,
    exact: true,
    Component: <HealthAssessment />,
    hasChildren: false,
  },
  {
    path: URL_FIRST_TIME_CHANGE_PASSWORD,
    exact: true,
    Component: <FirstTimeLogin />,
    hasChildren: false,
  },
  {
    path: URL_NOTIFICATION_TEMPLATE,
    exact: true,
    Component: <NotificationTemplate />,
    hasChildren: false,
  },
];

export default RoutesList;

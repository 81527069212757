//ROUTE BEFORE LOGIN
export const URL_LOGIN = "/login";
export const URL_SIGN_IN = "/";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_RESET_PASSWORD = "/reset-password";
export const URL_TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const URL_PRIVACY_POLICY = "/privacy-policy";

//ROUTE AFTER LOGIN
export const URL_DASHBOARD = "/dashboard";
export const URL_USERS = "users";
export const URL_QUICK_GUIDE = "quick-guide";
export const URL_STATIC_PAGE = "settings/static-page";
export const URL_EMAIL_TEMPLATE = "settings/email-template";
export const URL_CHANGE_PASSWORD = "settings/change-password";
export const URL_SCREENING_TOOLS = "hiv-self-test/question";
export const URL_SCREENING_TOOLS_USER = "hiv-self-test/user";
export const URL_DIRECTORY = "directory";
export const URL_FEED_FORUM = "feed-forum";
export const URL_FEED_FORUM_COMMENT = "/feed-forum/comment";
export const URL_ADMIN = "admin";
export const URL_NOTIFICATION = "notification";
export const URL_FORUM_REPORT = "report/forum-metrics";
export const URL_GENERAL_REPORT = "report/general-metrics";
export const URL_SCREENING_REPORT = "report/hiv-self-test-metrics";
export const URL_QUICK_GUIDE_POST_REPORT = "report/quick-guide-post-metrics";
export const URL_QUICK_GUIDE_LINK_REPORT = "report/quick-guide-link-metrics";
export const URL_HEALTH_ASSESSMENT_REPORT = "report/health-assessment-metrics";
export const URL_SEARCH_QUICK_GUIDE_REPORT =
  "report/search-quick-guide-metrics";
export const URL_SEARCH_DIRECTORY_REPORT = "report/search-directory-metrics";
export const URL_HEALTH_ASSESSMENT = "health-assessment";
export const URL_FIRST_TIME_CHANGE_PASSWORD = "change-password";
export const URL_NOTIFICATION_TEMPLATE = "notification-template";

export const API_URL = {
  AUTH: {
    LOGOUT: "auth/sign-out",
    LOGIN: "auth/sign-in",
    CHANGE_PASSWORD: "auth/change-password",
    FORGOT_PASSWORD: "auth/forgot-password",
    VERIFY_OTP: "auth/verify-otp",
    RESET_PASSWORD: "auth/reset-password",
  },
  QUICK_GUIDE: {
    GET: "quick-guide/list",
    ADD: "quick-guide/add",
    EDIT: "quick-guide/edit",
    DELETE: "quick-guide/delete",
  },
  SCREENING_TOOLS: {
    GET: "screening-question/list",
    ADD: "screening-question/add",
    EDIT: "screening-question/edit",
    DELETE: "screening-question/delete",
    USER_RESPONSE: "screening-question/user-response",
    USER_RESPONSE_DETAIL: "screening-question/user-response-detail",
  },
  USERS: {
    GET: "users/list",
    ADD: "users/add",
    EDIT: "users/edit",
    DELETE: "users/delete",
  },
  COUNTRY: {
    GET: "drop-down/country",
  },
  DIRECTORY: {
    GET: "directory/list",
    ADD: "directory/add",
    EDIT: "directory/edit",
    DELETE: "directory/delete",
  },
  STATIC_PAGE: {
    GET: "static-page/get",
    EDIT: "static-page/edit",
    GET_BY_SLUG: "static-page/get-by-slug",
  },
  EMAIL: {
    GET: "email-template/list",
    EDIT: "email-template/edit",
    TEMPLATE_NAME: "email-template/broadcast-template-name",
    GET_TEMPLATE: "email-template/template",
  },
  FEED_FORUM: {
    GET: "feed-forum/list",
    EDIT: "feed-forum/edit",
    DELETE: "feed-forum/delete",
    COMMENT: {
      GET_FEED_COMMENT: "feed-forum/comment/get-feed-comment",
      DELETE: "feed-forum/comment/delete",
      EDIT: "feed-forum/comment/edit",
    },
  },
  SUB_ADMIN: {
    GET: "sub-admin/list",
    ADD: "sub-admin/add",
    EDIT: "sub-admin/edit",
    DELETE: "sub-admin/delete",
  },
  NOTIFICATION: {
    GET: "notification/list",
    ADD: "notification/add",
  },
  DASHBOARD: {
    FORUM: {
      GET: "dashboard/forum-report",
    },
    GENERAL: {
      GET: "dashboard/general-report",
    },
    SCREENING_TOOLS: {
      GET: "dashboard/screening-tool-report",
    },
    QUICK_GUIDE: {
      POST_REPORT: "dashboard/quick-guide-post-open-report",
      LINK_REPORT: "dashboard/quick-guide-link-open-report",
    },
    HEALTH_ASSESSMENT: {
      GET: "dashboard/risk-assessment-report",
    },
    SEARCH: {
      QUICK_GUIDE_SEARCH: "dashboard/quick-guide-search-report",
      DIRECTORY_SEARCH: "dashboard/directory-search-report",
    },
  },
  HEALTH_ASSESSMENT: {
    USER_RESPONSE: "health-assessment/user-response",
    USER_RESPONSE_DETAIL: "health-assessment/user-response-detail",
  },
  NOTIFICATION_TEMPLATE: {
    GET: "mobile-notification-template/list",
    EDIT: "mobile-notification-template/edit",
  },
};

import { COLORS } from "Styles/Constants";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import { get } from "lodash";
import moment from "moment";
import {
  ADMIN_TYPE,
  AGE_RANGE,
  DEFAULT_CSV_FILE_NAME,
  GENDER_TYPE,
  HEALTH_ASSESSMENT_QUESTION_TYPE,
  QUESTION_TYPE,
  TEMPLATE_TYPE,
} from "./Constants";
import * as XLSX from "xlsx";

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getSideBarCurrentTab = () => {
  if (window && window.location.pathname) {
    const routes = window.location.pathname.split("/");
    if (routes.length > 1) {
      // const lastRoute = routes[routes.length - 1];
      const lastRoute = routes.slice(1).join("/");
      if (lastRoute) return [lastRoute];
      return [];
    }
    return [];
  }
  return [];
};

export const sortNumber = (a, b, key) => {
  if (
    a[key] !== undefined &&
    b[key] !== undefined &&
    b[key] !== null &&
    a[key] !== null
  ) {
    if (typeof a[key] === "string") {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
    }
  }
  return 0;
};

export const getColumnSearchProps = (dataIndex, displayName) => {
  let searchInput = null;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => (searchInput = node)}
          placeholder={`Search ${displayName}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={(e) => {
            e.stopPropagation();
            confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => {
            confirm();
          }}
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters && clearFilters();
            confirm();
          }}
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <SearchOutlined
          type="filter"
          style={{ color: filtered ? COLORS.PRIMARY : undefined }}
        />
      );
    },
    // onFilter: (value, record) => {
    //   if (typeof value === "string") {
    //     return get(record, dataIndex)
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase());
    //   }
    //   return get(record, dataIndex).includes(value);
    // },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.select());
      }
    },
  };
};

export const getFieldKeyValue = (filters) => {
  let searchField = {
    filterKey: "",
    filterValue: "",
  };

  let myFilters = { ...filters };
  delete myFilters.isActive;

  for (var key in myFilters) {
    if (
      myFilters[key] &&
      myFilters.hasOwnProperty(key) &&
      myFilters[key].length > 0
    ) {
      searchField.filterKey = key;
      searchField.filterValue =
        myFilters?.[key]?.length > 0
          ? myFilters[key]?.toString()
          : myFilters[key][0];
    }
  }

  return searchField;
};

export const UKCurrencyConverter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const disabledFutureDate = (current) => {
  // Set your maximum date here
  return current && current > moment().endOf("day");
};

export const getFile = (fileObj) => {
  if (Array.isArray(fileObj)) {
    return fileObj;
  }

  return (
    fileObj && fileObj.fileList.map((item) => ({ ...item, status: "done" }))
  );
};

export const handleGender = (gender) => {
  switch (gender) {
    case GENDER_TYPE.MALE:
      return "Male";
    case GENDER_TYPE.FEMALE:
      return "Female";
    case GENDER_TYPE.OTHER:
      return "Other";
    default:
      return "";
  }
};

export const handleAgeRange = (ageRange) => {
  switch (ageRange) {
    case AGE_RANGE.UNDER_18_YEARS:
      return "Under 18";
    case AGE_RANGE.YEARS_18_TO_24:
      return "18 to 24";
    case AGE_RANGE.YEARS_25_TO_29:
      return "25 to 29";
    case AGE_RANGE.YEARS_30_TO_36:
      return "30 to 36";
    case AGE_RANGE.YEARS_37_TO_42:
      return "37 to 42";
    case AGE_RANGE.YEARS_43_TO_49:
      return "43 to 49";
    case AGE_RANGE.YEARS_50_TO_65:
      return "50 to 65";
    case AGE_RANGE.OVER_65_YEARS:
      return "Over 65";
    default:
      break;
  }
};

export const handleRole = (adminType) => {
  switch (adminType) {
    case ADMIN_TYPE.ADMIN:
      return "Admin";
    case ADMIN_TYPE.SUB_ADMIN:
      return "Content Manager";
    default:
      break;
  }
};

export const handleQuestionType = (questionType) => {
  switch (questionType) {
    case QUESTION_TYPE.TEXT:
      return "Text";
    case QUESTION_TYPE.SINGLE_CHOICE:
      return "Single Choice";
    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return "Multiple Choice";
    case HEALTH_ASSESSMENT_QUESTION_TYPE.SUB_QUESTION:
      return "Sub Question";
    default:
      break;
  }
};

export const exportCSVFile = (
  dataArray,
  sheetName,
  FileName = DEFAULT_CSV_FILE_NAME
) => {
  const worksheet = XLSX.utils.json_to_sheet(dataArray);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, FileName);
};

export const handleTemplateType = (templateType) => {
  switch (templateType) {
    case TEMPLATE_TYPE.EVENT:
      return "Event";
    case TEMPLATE_TYPE.BROADCAST_NOTIFICATION_HEALTH_MINISTRY:
      return "Broadcast Notification Health Ministry";
    case TEMPLATE_TYPE.BROADCAST_CUSTOM_NOTIFICATION:
      return "Broadcast Custom Notification";
    default:
      return "Broadcast Custom Notification";
  }
};

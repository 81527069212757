import { ADMIN_TYPE, DASHBOARD_KEY } from "./Constants";
import {
  // URL_CHANGE_PASSWORD,
  URL_DIRECTORY,
  URL_EMAIL_TEMPLATE,
  URL_QUICK_GUIDE,
  URL_SCREENING_TOOLS,
  URL_USERS,
  URL_STATIC_PAGE,
  URL_SCREENING_TOOLS_USER,
  URL_FEED_FORUM,
  URL_ADMIN,
  URL_NOTIFICATION,
  URL_FORUM_REPORT,
  URL_SCREENING_REPORT,
  URL_QUICK_GUIDE_POST_REPORT,
  URL_QUICK_GUIDE_LINK_REPORT,
  URL_HEALTH_ASSESSMENT_REPORT,
  URL_SEARCH_QUICK_GUIDE_REPORT,
  URL_SEARCH_DIRECTORY_REPORT,
  URL_HEALTH_ASSESSMENT,
  URL_NOTIFICATION_TEMPLATE,
  // URL_GENERAL_REPORT,
} from "./Paths";

import { FaUsers } from "react-icons/fa";
import { FaAddressBook } from "react-icons/fa6";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import { IoNewspaperOutline } from "react-icons/io5";
import { GrUserAdmin } from "react-icons/gr";
import { IoIosNotifications } from "react-icons/io";
import { TbReportAnalytics } from "react-icons/tb";
// import { FaChartBar } from "react-icons/fa";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiHealthBookFill } from "react-icons/ri";

const getItem = (label, key, icon, children, userType) => {
  return {
    key,
    icon,
    children,
    label,
    userType,
  };
};

const MENU_ITEM = [
  // getItem(label, key, icon, [getItem(label, key, icon)](children))
  //1
  getItem(
    "Dashboard",
    DASHBOARD_KEY,
    <i className="ion-speedometer" />,
    null,
    ADMIN_TYPE.SUB_ADMIN
  ),
  //2
  getItem("CMS User", URL_ADMIN, <GrUserAdmin />, null, ADMIN_TYPE.ADMIN),
  //3
  getItem("Users", URL_USERS, <FaUsers />, null, ADMIN_TYPE.ADMIN),
  //4
  getItem(
    "Quick Guide",
    URL_QUICK_GUIDE,
    <i className="ion-ios-book-outline" />,
    null,
    ADMIN_TYPE.SUB_ADMIN
  ),
  //5
  getItem(
    "HIV Self Test",
    URL_SCREENING_TOOLS,
    <i className="ion-android-search" />,
    [
      getItem(
        "Question",
        URL_SCREENING_TOOLS,
        <FaRegCircleQuestion />,
        null,
        ADMIN_TYPE.ADMIN
      ),
      getItem(
        "User Response",
        URL_SCREENING_TOOLS_USER,
        <FaUser />,
        null,
        ADMIN_TYPE.ADMIN
      ),
    ],
    ADMIN_TYPE.ADMIN
  ),
  //6
  getItem(
    "Health Assessment",
    URL_HEALTH_ASSESSMENT,
    <RiHealthBookFill />,
    null,
    ADMIN_TYPE.ADMIN
  ),
  //7
  getItem(
    "Feeds Forum",
    URL_FEED_FORUM,
    <IoNewspaperOutline />,
    null,
    ADMIN_TYPE.SUB_ADMIN
  ),
  //8
  getItem(
    "Directory",
    URL_DIRECTORY,
    <TbReportAnalytics />,
    null,
    ADMIN_TYPE.SUB_ADMIN
  ),
  //9
  getItem(
    "Report",
    "report",
    <FaAddressBook />,
    [
      // getItem(
      //   "General Metrics",
      //   URL_GENERAL_REPORT,
      //   <FaChartBar />,
      //   null,
      //   ADMIN_TYPE.SUB_ADMIN
      // ),
      getItem(
        "Quick Guide Post Metrics",
        URL_QUICK_GUIDE_POST_REPORT,
        <i className="ion-ios-book-outline" />,
        null,
        ADMIN_TYPE.SUB_ADMIN
      ),
      getItem(
        "Quick Guide Link Metrics",
        URL_QUICK_GUIDE_LINK_REPORT,
        <i className="ion-ios-book-outline" />,
        null,
        ADMIN_TYPE.SUB_ADMIN
      ),
      getItem(
        "HIVST Metrics",
        URL_SCREENING_REPORT,
        <HiOutlineDocumentReport />,
        null,
        ADMIN_TYPE.SUB_ADMIN
      ),
      getItem(
        "Forum Metrics",
        URL_FORUM_REPORT,
        <IoNewspaperOutline />,
        null,
        ADMIN_TYPE.SUB_ADMIN
      ),
      getItem(
        "Health Risk Assessment",
        URL_HEALTH_ASSESSMENT_REPORT,
        <RiHealthBookFill />,
        null,
        ADMIN_TYPE.SUB_ADMIN
      ),
      getItem(
        "Search Quick Guide",
        URL_SEARCH_QUICK_GUIDE_REPORT,
        <i className="ion-android-search" />,
        null,
        ADMIN_TYPE.SUB_ADMIN
      ),
      getItem(
        "Search Directory",
        URL_SEARCH_DIRECTORY_REPORT,
        <i className="ion-android-search" />,
        null,
        ADMIN_TYPE.SUB_ADMIN
      ),
    ],
    ADMIN_TYPE.SUB_ADMIN
  ),
  //10
  getItem(
    "Settings",
    "settings",
    <i className="ion-gear-a" />,
    [
      // getItem(
      //   "Change Password",
      //   URL_CHANGE_PASSWORD,
      //   <i className="ion-ios-settings" />,
      //   null,
      //   ADMIN_TYPE.SUB_ADMIN
      // ),
      getItem(
        "Static Pages",
        URL_STATIC_PAGE,
        <i className="ion-ios-paper" />,
        null,
        ADMIN_TYPE.ADMIN
      ),
      getItem(
        "Email Template",
        URL_EMAIL_TEMPLATE,
        <i className="ion-email" />,
        null,
        ADMIN_TYPE.ADMIN
      ),
      getItem(
        "Notification Template",
        URL_NOTIFICATION_TEMPLATE,
        <IoIosNotifications />,
        null,
        ADMIN_TYPE.ADMIN
      ),
    ],
    ADMIN_TYPE.ADMIN
  ),
  //11
  getItem(
    "Broadcast Notification",
    URL_NOTIFICATION,
    <IoIosNotifications />,
    null,
    ADMIN_TYPE.ADMIN
  ),
];

export default MENU_ITEM;
